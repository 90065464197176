import axios from "axios";
import { Action } from "vuex";
import { RootState } from "@store/types";
import { BankState } from "../types";
import { Bank, BankType } from "@/types/bank";
import { MIN_AMOUNT_REFILL_CARD } from "@config/base";

let fromCache = false;

const fetchUserBanks: Action<BankState, RootState> = async ({
  commit,
  state,
}) => {
  if (state.userBanks.loading || fromCache) return;

  commit("setUserBanksLoading", true);

  try {
    const { data } = await axios.get<Bank[]>("/bank/list");

    const VIDAR_EUR_BANK_ID = 4; // Vidar (EUR)
    const THOR_USD_BANK_ID = 10; // Thor (USD)
    const THOR_EUR_BANK_ID = 37; // Thor (EUR)
    const ODIN_USD_BANK_ID = 3; // Odin (USD)
    const MAGNUS_USD_BANK_ID = 23; // Magnus (USD)
    const VIPER_USD_BANK_ID = 24; // Viper (EUR)

    data.forEach((bank) => {
      bank.minDepositAmount = MIN_AMOUNT_REFILL_CARD;

      if (bank.info.binId === "534271") {
        bank.extraBankExchangeFeeMult = 3;

        delete bank.bankExchangeFeeMult;
      }

      if (bank.id === THOR_USD_BANK_ID) {
        delete bank.transactionFee;

        bank.bankExchangeFeeMult = 2;
        bank.transactionFeeCurrency = 0;
      } else if (bank.id === MAGNUS_USD_BANK_ID) {
        bank.extraBankExchangeFeeMult = bank.bankExchangeFeeMult;
        bank.declineTransactionFee = 0.3;
        bank.externalInfo = [
          {
            transKey:
              "$vuetify.dashboard.bank_info.odin.auto_closed_during_one_month",
          },
        ];

        delete bank.bankExchangeFeeMult;
      } else if (bank.id === VIDAR_EUR_BANK_ID) {
        delete bank.transactionFee;

        bank.bankExchangeFeeMult = 2;
        bank.transactionFeeCurrency = 0;
        bank.declineTransactionFee = 0.3;
      } else if (bank.id === ODIN_USD_BANK_ID) {
        bank.externalInfo = [
          {
            transKey:
              "$vuetify.dashboard.bank_info.odin.auto_closed_during_one_month",
          },
        ];
      } else if (bank.id === VIPER_USD_BANK_ID) {
        bank.extraBankExchangeFeeMult = bank.bankExchangeFeeMult;
        bank.declineTransactionFee = 0.3;

        delete bank.bankExchangeFeeMult;
      } else if (bank.bankType === BankType.TODACARDS) {
        bank.minDepositAmount = 25;
        bank.fixedFee = 0.5;
        bank.declineTransactionFee = 0.5;

        delete bank.bankExchangeFeeMult;
      } else if (bank.bankType === BankType.ADMEDIA) {
        bank.extraBankExchangeFeeMult = bank.bankExchangeFeeMult;

        delete bank.bankExchangeFeeMult;
      } else if (bank.bankType === BankType.CAPITALIST) {
        bank.fixedFeeThreshold = 0.25;
      }

      Object.freeze(bank);
    });

    data.sort((a, b) => {
      const displayOrderFirstBank = a.displayOrder || 0;
      const displayOrderSecondBank = b.displayOrder || 0;

      return displayOrderFirstBank + displayOrderSecondBank;
    });

    fromCache = true;

    window.setTimeout(() => {
      fromCache = false;
    }, 60e3);
    commit("setUserBanks", data);
    commit("setUserBanksLoading", false);
  } catch {
    commit("setUserBanksLoading", false);
  }
};

export default fetchUserBanks;
