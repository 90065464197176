var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('VuetifyLazyComponent',_vm._g(_vm._b({attrs:{"transition":_vm.localTransition,"fullscreen":_vm.fullscreen,"component-name":"VDialog"},model:{value:(_vm.showedDialog),callback:function ($$v) {_vm.showedDialog=$$v},expression:"showedDialog"}},'VuetifyLazyComponent',_vm.$attrs,false),_vm.$listeners),[(_vm.useCardContainer && _vm.showedContent)?_c('v-card',{class:{
      'pa-7': !_vm.$vuetify.breakpoint.mobile && _vm.hasPadding,
      'pa-5': _vm.$vuetify.breakpoint.mobile && _vm.hasPadding,
    }},[(!_vm.hiddenCloseButton)?_c('v-btn',{style:({
        top: '1px',
        right: '1px',
      }),attrs:{"width":_vm.$vuetify.breakpoint.mobile ? 24 : 28,"height":_vm.$vuetify.breakpoint.mobile ? 24 : 28,"data-autotest-id":_vm.closeButtonAutotestId,"icon":"","absolute":"","color":"grey"},on:{"click":function($event){return _vm.toggleDialog(false)}}},[_c('v-icon',{attrs:{"size":_vm.$vuetify.breakpoint.mobile ? 17 : 20}},[_vm._v(" $close ")])],1):_vm._e(),_vm._t("content")],2):(_vm.showedContent)?_vm._t("content"):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }