import { availableLangs } from "@config/lang";
import { LangCode } from "@/types/lang";

export function getUserLang(): LangCode {
  const searchParams = new URLSearchParams(window.location.search.slice(1));

  const langFromQuery = searchParams.get("lang") as LangCode | null;

  if (langFromQuery && checkValidLangCode(langFromQuery)) {
    return langFromQuery;
  }

  const langCodeFromLocalStorage = localStorage.getItem(
    "lang"
  ) as LangCode | null;

  if (
    langCodeFromLocalStorage &&
    checkValidLangCode(langCodeFromLocalStorage)
  ) {
    return langCodeFromLocalStorage;
  }

  const langCodeFromNavigator = navigator.language.split("-")[0] as
    | LangCode
    | undefined;

  if (langCodeFromNavigator && checkValidLangCode(langCodeFromNavigator)) {
    return langCodeFromNavigator;
  }

  return LangCode.ENGLISH;
}

export function setUserLang(code: string): void {
  document.documentElement.lang = code;
  return localStorage.setItem("lang", code);
}

export function checkValidLangCode(langCode?: string): boolean {
  if (!langCode) return false;

  return availableLangs.some(
    (availableLang) => availableLang.code === langCode
  );
}
