import Vue from "vue";
import locales from "./locales";

import Vuetify, {
  VApp,
  VSpacer,
  VDivider,
  VFadeTransition,
  VExpandTransition,
  VScaleTransition,
  VScrollXTransition,
  VScrollYTransition,
  VTabTransition,
  VFabTransition,
  VMenuTransition,
  VSlideYTransition,
  VExpandXTransition,
  VDialogTransition,
  VSlideXTransition,
  VSlideYReverseTransition,
  VScrollYReverseTransition,
  VMain,
  VProgressCircular,
  VProgressLinear,
  VCard,
  VTextField,
  VTextarea,
  VForm,
  VCardText,
  VCardActions,
  VBtn,
  VIcon,
  VList,
  VListItem,
  VSubheader,
  VListGroup,
  VLabel,
  VInput,
} from "vuetify/lib";
import { Ripple } from "vuetify/lib/directives";
import { getUserLang } from "@/lib/userLang";
import { MOBILE_BREAKPOINT, SCROLLBAR_WIDTH } from "@config/base";
import icons from "./icons";

Vue.use(Vuetify, {
  components: {
    VApp,
    VDivider,
    VSpacer,
    VFadeTransition,
    VExpandTransition,
    VScaleTransition,
    VScrollXTransition,
    VScrollYTransition,
    VTabTransition,
    VFabTransition,
    VMenuTransition,
    VSlideYTransition,
    VExpandXTransition,
    VDialogTransition,
    VSlideXTransition,
    VSlideYReverseTransition,
    VScrollYReverseTransition,
    VMain,
    VProgressCircular,
    VProgressLinear,
    VCard,
    VTextField,
    VTextarea,
    VForm,
    VCardText,
    VCardActions,
    VBtn,
    VIcon,
    VList,
    VListItem,
    VSubheader,
    VListGroup,
    VLabel,
    VInput,
  },
  directives: {
    Ripple,
  },
});

const userLang = getUserLang();

document.documentElement.lang = userLang;

export default new Vuetify({
  icons: {
    values: icons,
  },
  lang: {
    current: userLang,
    locales,
  },

  breakpoint: {
    thresholds: {
      md: 1280 + SCROLLBAR_WIDTH,
      lg: 1860 + SCROLLBAR_WIDTH,
    },
    mobileBreakpoint: MOBILE_BREAKPOINT,
    scrollBarWidth: SCROLLBAR_WIDTH,
  },
  theme: {
    options: {
      cspNonce: "dQw4w9WgXcQ",
    },
    themes: {
      light: {
        primary: "#4E96FA",
        error: "#FF515F",
        success: "#45DF82",
        warning: "#FFC008",
      },
    },
  },
});
