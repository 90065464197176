import { Role } from "@/types/role";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

export const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/Home/Home.vue"),
    meta: {
      layout: "EmptyLayout",
      access: {
        onlyNotAuthenticatedUsers: true,
        onlyNotActiveUsers: true,
      },
    },
  },
  {
    path: "/ghost-login",
    name: "ghost-login",
    component: () =>
      import(
        /* webpackChunkName: "ghost-login" */ "@/views/GhostLogin/GhostLogin.vue"
      ),
  },
  {
    path: "/cards/with-limit",
    name: "cards-with-limit",
    component: () =>
      import(/* webpackChunkName: "cards" */ "@/views/Cards/Cards.vue"),
    meta: {
      access: {
        role: [Role.ROLE_TEAMLEAD, Role.ROLE_OWNER],
        onlyAuthenticatedUsers: true,
      },
    },
  },
  {
    path: "/cards/with-balance",
    name: "cards-with-balance",
    component: () =>
      import(/* webpackChunkName: "cards" */ "@/views/Cards/Cards.vue"),
    meta: {
      access: {
        role: [
          Role.ROLE_TEAMLEAD,
          Role.ROLE_OWNER,
          Role.ROLE_SUPERADMIN,
          Role.ROLE_MEDIABUYER,
          Role.ROLE_SUPPORT,
        ],
        onlyAuthenticatedUsers: true,
      },
    },
  },
  {
    path: "/cards",
    name: "cards",
    redirect: {
      name: "cards-with-balance",
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "@/views/Profile/Profile.vue"),
    meta: {
      access: {
        role: [
          Role.ROLE_TEAMLEAD,
          Role.ROLE_OWNER,
          Role.ROLE_SUPERADMIN,
          Role.ROLE_MEDIABUYER,
          Role.ROLE_SUPPORT,
        ],
        onlyAuthenticatedUsers: true,
      },
    },
  },
  {
    path: "/transactions",
    name: "transactions",
    component: () =>
      import(
        /* webpackChunkName: "transactions" */ "@/views/Transactions/Transactions.vue"
      ),
    meta: {
      access: {
        role: [
          Role.ROLE_TEAMLEAD,
          Role.ROLE_OWNER,
          Role.ROLE_SUPERADMIN,
          Role.ROLE_MEDIABUYER,
          Role.ROLE_SUPPORT,
        ],
        onlyAuthenticatedUsers: true,
      },
    },
  },
  {
    path: "/refill",
    name: "refill",
    component: () =>
      import(/* webpackChunkName: "refill" */ "@/views/Refill/Refill.vue"),
    meta: {
      access: {
        role: [
          Role.ROLE_TEAMLEAD,
          Role.ROLE_OWNER,
          Role.ROLE_SUPERADMIN,
          Role.ROLE_MEDIABUYER,
          Role.ROLE_SUPPORT,
        ],
        onlyAuthenticatedUsers: true,
      },
    },
  },
  {
    path: "/team",
    name: "team",
    component: () =>
      import(/* webpackChunkName: "team" */ "@/views/Team/Team.vue"),
    meta: {
      access: {
        role: [
          Role.ROLE_TEAMLEAD,
          Role.ROLE_OWNER,
          Role.ROLE_SUPERADMIN,
          Role.ROLE_MEDIABUYER,
          Role.ROLE_SUPPORT,
        ],
        onlyAuthenticatedUsers: true,
      },
    },
  },
  {
    path: "/statistic",
    name: "statistic",
    redirect: {
      name: "statistic-by-type",
    },
  },
  {
    path: "/statistic/by-type",
    name: "statistic-by-type",
    component: () =>
      import(
        /* webpackChunkName: "statistic" */ "@/views/Statistic/Statistic.vue"
      ),
  },
  {
    path: "/statistic/by-status",
    name: "statistic-by-status",
    component: () =>
      import(
        /* webpackChunkName: "statistic" */ "@/views/Statistic/Statistic.vue"
      ),
  },
  {
    path: "/cash-flow",
    name: "cash-flow",
    component: () =>
      import(
        /* webpackChunkName: "cash-flow" */ "@/views/CashFlow/CashFlow.vue"
      ),
    meta: {
      access: {
        role: [
          Role.ROLE_TEAMLEAD,
          Role.ROLE_OWNER,
          Role.ROLE_SUPERADMIN,
          Role.ROLE_MEDIABUYER,
          Role.ROLE_SUPPORT,
        ],
        onlyAuthenticatedUsers: true,
      },
    },
  },
  {
    path: "/currency-converter",
    name: "currency-converter",
    component: () =>
      import(
        /* webpackChunkName: "currency-converter" */ "@/views/CurrencyConverter/CurrencyConverter.vue"
      ),
    meta: {
      access: {
        role: [
          Role.ROLE_TEAMLEAD,
          Role.ROLE_OWNER,
          Role.ROLE_SUPERADMIN,
          Role.ROLE_MEDIABUYER,
          Role.ROLE_SUPPORT,
        ],
        onlyAuthenticatedUsers: true,
      },
    },
  },
  {
    path: "/referral-program",
    name: "referral-program",
    component: () =>
      import(
        /* webpackChunkName: "currency-converter" */ "@/views/ReferralProgram/ReferralProgram.vue"
      ),
    meta: {
      access: {
        role: [Role.ROLE_TEAMLEAD],
        onlyAuthenticatedUsers: true,
      },
    },
  },
  {
    path: "/partners",
    name: "partners",
    component: () =>
      import(
        /* webpackChunkName: "partners" */ "@/views/Partners/Partners.vue"
      ),
    meta: {
      access: {
        onlyAuthenticatedUsers: true,
      },
    },
  },
  {
    path: "/faq",
    name: "faq",
    component: () =>
      import(/* webpackChunkName: "faq" */ "@/views/FAQ/FAQ.vue"),
    meta: {
      access: {
        role: [
          Role.ROLE_TEAMLEAD,
          Role.ROLE_OWNER,
          Role.ROLE_SUPERADMIN,
          Role.ROLE_MEDIABUYER,
          Role.ROLE_SUPPORT,
        ],
        onlyAuthenticatedUsers: true,
      },
    },
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/Login/Login.vue"),
    meta: {
      layout: "AuthLayout",
      access: {
        onlyNotAuthenticatedUsers: true,
        onlyNotActiveUsers: true,
      },
    },
  },
  {
    path: "/sign-up",
    name: "sign-up",
    component: () =>
      import(/* webpackChunkName: "sign-up" */ "@/views/SignUp/SignUp.vue"),
    meta: {
      layout: "AuthLayout",
      access: {
        onlyNotAuthenticatedUsers: true,
        onlyNotActiveUsers: true,
      },
    },
  },
  {
    path: "/verify",
    name: "verify",
    component: () =>
      import(
        /* webpackChunkName: "verify" */ "@/views/VerifyAccount/VerifyAccount.vue"
      ),
    meta: {
      layout: "AuthLayout",
      access: {
        onlyNotAuthenticatedUsers: true,
        onlyNotActiveUsers: true,
      },
    },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ "@/views/ForgotPassword/ForgotPassword.vue"
      ),
    meta: {
      layout: "AuthLayout",
      access: {
        onlyNotAuthenticatedUsers: true,
        onlyNotActiveUsers: true,
      },
    },
  },
  {
    path: "/blocked-account",
    name: "blocked-account",
    component: () =>
      import(
        /* webpackChunkName: "blocked-account" */ "@/views/BlockedAccount/BlockedAccount.vue"
      ),
    meta: {
      layout: "AuthLayout",
      access: {
        onlyNotAuthenticatedUsers: true,
        onlyNotActiveUsers: true,
      },
    },
  },
  {
    path: "/confirm-email",
    name: "confirm-email",
    component: () =>
      import(
        /* webpackChunkName: "confirm-email" */ "@/views/ConfirmEmail/ConfirmEmail.vue"
      ),
    meta: {
      layout: "EmptyLayout",
      access: {
        onlyNotAuthenticatedUsers: true,
        onlyNotActiveUsers: true,
      },
    },
  },
  {
    path: "/404",
    name: "404",
    component: () =>
      import(/* webpackChunkName: "not-found" */ "@/views/Error/404/404.vue"),
    meta: {
      layout: "EmptyLayout",
    },
  },
  {
    path: "*",
    component: () =>
      import(
        /* webpackChunkName: "page-not-found" */ "@/views/PageNotFound/PageNotFound.vue"
      ),
    meta: {
      layout: "EmptyLayout",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
  scrollBehavior(to, from) {
    if (to.path !== from.path) {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
