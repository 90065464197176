import * as admin from "./admin";
import * as auth from "./auth";
import * as partner from "./partner";
import * as card from "./card";
import * as dashboard from "./dashboard";
import * as feed from "./feed";
import * as profile from "./profile";
import * as promocode from "./promocode";
import * as referral from "./referral";
import * as team from "./team";
import * as transaction from "./transaction";
import * as warning from "./warning";
import * as statistic from "./statistic";
import * as survey from "./survey";

import { IS_PROD } from "@config/base";

const API = {
  admin,
  auth,
  partner,
  card,
  dashboard,
  feed,
  profile,
  promocode,
  team,
  transaction,
  warning,
  statistic,
  survey,
  referral,
};

export const getBaseURL = () =>
  IS_PROD ? process.env.VUE_APP_API_URL : "/multicards-api/";

export default API;
