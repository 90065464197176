import axios from "axios";
import { Partner } from "@/types/partner";
import { LangCode } from "@/types/lang";

export default async function fetchPartners({
  lang,
}: {
  lang: LangCode;
}): Promise<Partner[]> {
  const { data } = await axios.get<Partner[]>("/partners/list", {
    params: {
      lang,
    },
  });

  return data;
}
