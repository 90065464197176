import axios from "axios";

export default async function submitSurvey(
  payload: {
    question: string;
    answer: string;
  }[]
) {
  const { data } = await axios.post("/survey/submit", payload);

  return data;
}
