/* eslint-disable @typescript-eslint/ban-ts-comment */
export default function yieldToMain(): Promise<void> {
  // @ts-ignore
  if ("scheduler" in window && "yield" in scheduler) {
    // @ts-ignore
    return window.scheduler.yield();
  }

  return new Promise((resolve) => {
    setTimeout(resolve, 0);
  });
}
