export type UserWebPreferencesValue<T = unknown> = Record<string, T>;

export enum UserWebPreferencesKey {
  CARDS_LIST = "CARDS_LIST",
  BALANCE_CARDS = "BALANCE_CARDS",
  LIMIT_CARDS = "LIMIT_CARDS",
  TRANSACTIONS = "TRANSACTIONS",
  CASH_FLOW = "CASH_FLOW",
  TEAM = "TEAM",
  REFILL = "REFILL",
  STATISTIC = "STATISTIC",
  STATISTIC_BY_STATUS = "STATISTIC_BY_STATUS",
  WALLET_TRANSFER_FUNDS = "WALLET_TRANSFER_FUNDS",
  FAVORITE_BANKS_ISSUE_CARD = "FAVORITE_BANKS_ISSUE_CARD",
  HEADER_WALLETS = "HEADER_WALLETS",
  FEEDBACK_SURVEY = "FEEDBACK_SURVEY",
}
