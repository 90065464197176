import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
  inheritAttrs: false,
})
export default class Dialog extends Vue {
  @Prop({ type: Boolean, default: false })
  private readonly useCardContainer!: boolean;
  @Prop({ type: Boolean, default: true })
  private readonly hasPadding!: boolean;
  @Prop({ type: Boolean, default: false }) private readonly value!: boolean;
  @Prop({ type: Boolean, default: false })
  private readonly fullscreen!: boolean;
  @Prop({ type: String })
  private readonly transition?: string;
  @Prop({ type: String }) private readonly closeButtonAutotestId?: string;
  @Prop({ type: Boolean, default: true })
  private readonly renderContentAfterShow!: boolean;
  @Prop({ type: Boolean, default: false })
  private readonly hiddenCloseButton!: boolean;

  private showedContent = false;
  private showedDialog = false;

  private get localTransition() {
    if (!this.transition) {
      return this.fullscreen
        ? "dialog-bottom-transition"
        : "scroll-y-reverse-transition";
    }

    return this.transition;
  }

  private toggleDialog(showed = !this.showedDialog) {
    this.showedDialog = showed;
  }

  private mounted() {
    let transitionTimeoutId = 0;

    this.toggleDialog(this.value);
    this.showedContent = this.showedDialog;

    this.$watch(
      () => {
        return this.value;
      },
      (value) => {
        this.toggleDialog(value);
      }
    );

    this.$watch(
      () => {
        return this.showedDialog;
      },
      (showedDialog) => {
        window.clearTimeout(transitionTimeoutId);

        this.showedContent = true;

        if (!showedDialog) {
          transitionTimeoutId = window.setTimeout(() => {
            this.showedContent = !this.renderContentAfterShow;
            this.$emit("input", false);
          }, 300);
        } else {
          this.$emit("input", true);
        }
      }
    );

    this.$once("hook:beforeDestroy", () => {
      window.clearTimeout(transitionTimeoutId);
    });
  }
}
